import React, { useEffect, useState } from "react";


export default function Withdrawal(){

    const[email,setEmail] = useState('');
    const[pw,setPw] = useState('');

    const[emailValid, setEmailValid] = useState(false);
    const[pwValid, setPwValid] = useState(false);
    const[notAllow, setNotAllow] = useState(true);

    const handleEmail = (e) => {
      const newEmail = e.target.value.trim();
      setEmail(newEmail);
      const regex = 
      /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      if (regex.test(newEmail)) {
          setEmailValid(true);
        } 
      else {
          setEmailValid(false);
        }
      };
        
    const handlePw = (e) => {
      const newPw = e.target.value.trim();
      setPw(newPw);
        const regex =
          /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+])(?!.*[^a-zA-z0-9$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/;
        if (regex.test(newPw)) {
          setPwValid(true);
        } 
      else {
          setPwValid(false);
        }
      };
    
    useEffect(() => {
        if(emailValid && pwValid) {
          setNotAllow(false);
          return;
        }
        setNotAllow(true);
      }, [emailValid, pwValid]);
    
    const onClickConfirmButton = () => {
      const loginData = {
        email: email,
        password: pw
      };
      
      // 서버에 POST 요청을 보냄

      fetch('/withdrawal/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginData)
      })
      .then(response => {
        console.log(response);
        if (!response.ok) {
          throw new Error('서버 응답 실패');
        }
        return response.json();
      })
      .then(data => {
        console.log('data', data); // 이제 data는 객체 형태로 제공됩니다.
      
        // 여기서부터는 data 객체의 속성에 직접 접근 가능합니다.
        console.log('message', data.message);
        
        if(data.message === undefined){

            let redirectUrl
            let uid
            let nickname

            console.log(data);

            console.log('uid', data[0].uid);
            console.log('nickname', data[0].nickname);

            uid = data[0].uid
            nickname = data[0].nickname

            alert(`${nickname}’s membership information has been confirmed.`);

            const confirmed = window.confirm("Do you want to cancel your membership? All information stored on the Just Jump trading site will be deleted. However, you must separately organize your current positions and order details on the exchange.");

            if (confirmed) {
                async function checked_member() {
                    try {
                        const response = await fetch('/admin/revoke', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ uid }),
                        });
                
                        if (!response.ok) {
                            throw new Error('Failed to revoke user');
                        }
                
                        const data = await response.json();
                        // 해당 사용자의 정보만을 업데이트합니다.
                        if (data.login_possible === 'revoke') {
                            alert(`${nickname}'s membership has been canceled. thank you Go to the main page.`);
                            redirectUrl = '/';
                            window.location.href = redirectUrl;
                        }
                    } catch (error) {
                        console.error('Error revoking user:', error);
                        // 여기서 오류가 발생한 경우에 대한 처리를 추가할 수 있습니다.
                    }
                    
                    window.location.href = redirectUrl;
                }
                checked_member();
          }
      } else {
      alert(data.message);
     }
    })
      .catch(error => {
        console.error('오류 발생:', error);
        alert(error);
      });
      
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        onClickConfirmButton();
      }
    };

    return(
        <div className="page">
            <div className="titleWrap">
                Just Jump trading system(BINGX)
                <br/>
                Membership withdrawal page.
                <br/>
                Please enter your information again
            </div>

            <div className="contentWrap">
                <div className="inputTitle">E-mail address</div>
                <div className="inputWrap">
                    <input 
                    type='text'
                    className="input"
                    placeholder="test@gmail.com"
                    value={email}
                    onChange={handleEmail}
                    onKeyDown={handleKeyDown} 
                    />
                </div>
                <div className="errorMessageWrap">
                    {!emailValid && email.length > 0 && (
                        <div>Please enter a valid email.</div>
                )}
            </div>

            <div style={{ marginTop: "26px"}} className="inputTitle">Password</div>
                <div className="inputWrap">
                    <input 
                    type='password'
                    className="input"
                    placeholder="At least 8 characters including English letters (including capital letters), numbers, and special characters"
                    value={pw}
                    onChange={handlePw}
                    onKeyDown={handleKeyDown}
                     />
                </div>
                <div className="errorMessageWrap">
                    {!pwValid && pw.length > 0 && (
                        <div>Please enter at least 8 characters including letters, numbers, and special characters.</div>
                    )}
                </div>
            </div>

            <div>
                <button onClick={onClickConfirmButton} disabled={notAllow} className="bottomButton">
                    확인
                </button>
            </div>
            <div className="signuplink">
                <a href="/">Main page</a>
                <a href="/Login_ats">Log in page</a>
            </div>
        </div>
    );
}