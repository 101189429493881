import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';


export default function SelectPage(){

    const [cookies] = useCookies(['uuid']);

    // 쿠키에서 UUID를 이용하여 사용자 정보 가져오기
    const[uid,setUID] = useState(0);
    const[nickname,setNickname] = useState('');

    useEffect(() => {
      if (!cookies.uuid) {
        alert("Your login information doesn't exist. Go to the login page.");
        window.location.href = `/Login_ats`;
        return;
      } else {
        const uuid = cookies.uuid;
        fetchUserInfo(uuid);
      }
    }, []);

    useEffect(() => {
      console.log('클라이언트 uuid', cookies.uuid);
    }, [cookies.uuid]);
  
    const fetchUserInfo = (uuid) => {
      fetch(`/SetPage/user?uuid=${uuid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          const user = data[0];
          setUID(user.uid);
          setNickname(user.nickname);
        })
        .catch(error => {
          alert("Your login information doesn't exist. Go to the login page.");
          window.location.href = `/Login_ats`;
          return;
        });
    };
    
    console.log(uid);

    // Web version select
    const onWeb_version = () => {
        fetch(`/webversion_selected?uid=${uid}`)
        .then((response) => {
        if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
        }
        return response.json()
        })
        .then((result) => {
          alert('Move to web version page');
          console.log('onweb_DB에서 받아온 데이터',result);
          window.location.href = `/SetPage`;
        })
        .catch((error) => {
        console.log('에러남',error);
        })
    };

    // Hook version select
    const onHook_version = () => {
        fetch(`/hookversion_selected?uid=${uid}`)
        .then((response) => {
        if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
        }
        return response.json()
        })
        .then((result) => {
          console.log(result)
          if(result['message'] === 'ok'){
            alert('Move to Hook version page');
            console.log('onhook_DB에서 받아온 데이터',result);
            window.location.href = `/SetPage_hook`;
          } else {
            alert(result['message'])
          }
        })
        .catch((error) => {
        console.log('에러남',error);
        })
    };


    return (
      <div className="container_select">
        <header className="header_select">
          Just Jump trading system(BINGX)
        </header>
        <div className="button-container_select">
            <span>UID : {uid}</span>
            <span>Welcome to {nickname}</span>
            Select automatic trading program version
          <button onClick={() => onWeb_version()} className="button_select">Web version</button>
            Additionally, a new version is being prepared.
          {/* <button onClick={() => onHook_version()} className="button_select" disabled={true}>New version in preparation</button> */}
        </div>
      </div>
    );
  }
  