import React, { useEffect } from 'react';

// const Main = () => {
//     useEffect(() => {
//         window.location.href = 'https://bingx.ai';
//     }, []);
// };

import { Link } from 'react-router-dom';

const Main = () => {
    return (
        <>
            <h3>Auto trading system (BINGX version)</h3>
            <div className="button-container-main">
                <Link to="/Signup_ats" className="button-main">Sign up</Link>
                <Link to="/Login_ats" className="button-main">Log in</Link>
                <Link to="/SetPage" className="button-main">Set Page</Link>
            </div>
        </>
    );
};

export default Main;
